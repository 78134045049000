import { defineComponent, reactive, getCurrentInstance, ComponentInternalInstance, ref } from 'vue'
import { checkRegisterPhone, getValidateCode, sendSms, register, findBySearch } from '@/api/index'
import { useRouter } from 'vue-router'
import { Login } from '@/interface/index'
import { Dialog, Checkbox, Popup, Toast } from 'vant'

const tils= require('@/utils/tils')
import './index.less'

let counstomToast:any = ''
let perInfo: any = {}

export default defineComponent({
    name: 'Register',
    setup () {
        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties
        const router = useRouter()
        const bool = ref(false) // 是否显示其他职位
        const isSub = ref(true) //是否可以点击下一步
        const times = ref(30)
        const show = ref(true)
        const checked = ref(false)
        const timeNum: any = ref(null)
        const showPopup = ref(false)
        const perData:any = reactive({data: []})


        const form = reactive({data: {
            code: '',//验证码 register
            companyId: 0,//公司编号
            companyName: '',//公司名称
            gender: 1,//性别
            jobGrade: '1', //我是
            name: '',//姓名
            phone: '',// 手机号
            otherjob: '',//其他职位
            referrer: '',//推荐人
            shortName: '',//牌匾名
        }})

        const companyList = reactive({data: []})
        const code = ref('') //验证码
        const codePic = ref('')

        const genderList = [
            { text: '先生', value: 1 },
            { text: '女士', value: 0 },
        ]

        const registerUI = () => {
            return (
                <div>
                    <img class='register-success-icon' src='https://img.fanwoon.com/ok%402x.png' onClick={onReloadCode} />
                    <div class='register-success'>注册成功，我们会在1个工作日内为您开通账号，请留意短信通知</div>
                </div>
            )
        }

        const searchCompanyUI = () => {
            return (
                <div class="company-bg">
                    <div class="company-list">
                        {
                                companyList.data.map((item: any) => {
                                return <div class="item" key={item.id}>{item.name}</div>
                            })
                        }
                    </div>
                </div>
            )
        }

        // 发送短信
        const onSendSms = async (data: Login) => {
            const res: any = await sendSms(data) 
            if (res.code === 0) {
                onCode() //倒计时 
            }
            proxy.$toast(res.message);
        }

         //刷新验证码
       const onReloadCode = () => {
            const path = getValidateCode + '?phone=' + form.data.phone + '&verify=' + Math.random()
            codePic.value = path;
        }

        const verifyUI = () => {
            return (
                <div>
                    <img class='tishi-icon' src='https://img.fanwoon.com/tishi.png' />
                    <img src={codePic.value} onClick={onReloadCode} />
                    <input class='code-box' v-model={ code.value } placeholder="请输入验证码" />
                </div>
            )
       }

        const showVerify = () => {
            onReloadCode()
            Dialog.confirm({
                    message: verifyUI,
                    closeOnClickOverlay: true,
                    confirmButtonColor: '#FE4A49'
                }).then(() => {
                if (!code.value) {
                    return proxy.$toast('请输入图形验证码!');
                }
                onSendSms({phone: form.data.phone, verify: code.value})
                }).catch(() => {
                    // on cancel
                    console.log('cancel')
            });
        }

        const onOpenVerify = async () => {
            code.value = ''
            if (!form.data.phone) return proxy.$toast('手机号不能为空!');
            const res:any = await checkRegisterPhone(form.data.phone)
            if (res.data === 0 ) return showVerify()
            return proxy.$toast('该手机号已注册!');
        }

        const onLogin = () => {
            router.push({path: '/login'})
        }

        //验证姓名
        const validatorLeng20 = (value:string): string | boolean => {
           if (!value)return "姓名不能为空！"
           if (value.length > 20) return "最多不超过20个字符!"
           return true
        }

        //验证其他职位
        const validatorOtherJob = (value: string): string | boolean => {
            if (!value) return '必须要输入具体的身份!'
            if (value.length > 20) return '最多不超过20个字符!'
            return true
        }

        //验证手机
        const validatorPhone = (value: string): string | boolean => {
            const rgx = /^1\d{10}$/;
            if (!value) return '请输入手机号!'
            if (value != null && value !== "" && !rgx.test(value)) {
                return "手机号码格式有误，请重填"
            }
            return true
        }

        //验证公司名称
        const validatorCompany = (value: string): string | boolean => {
            if (!value) return '请填写营业执照上的名字!'
            if (value.length > 30) return '最多不超过30个字符!'
            return true
        }

        //验证牌匾名
        const validatorShortName = (value: string): string | boolean => {
            if (!value)return '请输入线下门店牌匾名，如“翻腕地产餐饮"'
            if (value.length > 30) return '最多不超过30个字符!'
            return true
        }

        const onJobGrade = (val:number) => {
            Number(val) === 4 ? bool.value = true : bool.value = false
            console.log(bool.value)
        }

        //注册
        const onSubmit = async(values: any) => {
            if(!isSub.value)return false
            if (checked.value && JSON.stringify(perInfo) == "{}") {
                Toast("选择您的推荐人");
                return 
            }
            
            isSub.value = false
            const data = { ...form.data, ...values, omsAccountId: perInfo.id }
            const res:any = await register(data)
            perInfo = {}
            const code = res.code
            code !== 0 ? proxy.$toast(res.message) : (
                Dialog.alert({
                    message: registerUI,
                    theme: 'round-button',
                    confirmButtonColor: '#fff'
                  }).then(() => {
                    // on close
                    router.push({path: '/login'})
                  })
            )
            isSub.value = true
            return true
        }

        //倒计时
        const onCode = () => {
            show.value = false 
            let timer:any = setInterval(() => {
                times.value--
                timeNum.value.innerHTML = times.value + 's获取验证码'
                if (times.value === 0) {
                    clearInterval(timer)
                    timer = null
                    show.value = true
                    times.value = 30
                }
            }, 1000)
        }

        const onOpenPopup = () => {
            showPopup.value = true
        }

        const onClosePopup = () => {
            showPopup.value = false
        }

        const _findBySearch = async (name:any) => {
            const res:any = await findBySearch({limit: 10, start: 0, data: name})
            counstomToast.clear()
            console.log(res.data)
            if (res.data.length > 0) {
                onOpenPopup()
                perData.data = res.data
            } else {
                Toast("暂无次推荐人");
                perData.data = []
            }
        }

        const onSearchPer = tils.debounce((e:any) => {
            const val:any = e.target.value
            counstomToast = Toast.loading({
                message: '加载中...',
                forbidClick: true,
              });
            _findBySearch(val)
        }, 1000)

        const onSelectPer = (item:any) => {
            perInfo = item
            form.data.referrer = item.name + `(${item.empNo})`
            onClosePopup()
        }

        return () => (
            <div class="register-page">
                 <div class="top-header-page flex-box justify-content-between">
                    <div>
                        <div>门店账号注册</div>
                        <div class="sub-title">快速注册，免费招人</div>
                    </div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <van-form onSubmit={onSubmit}>
                    <div class="form-list">
                        <div class="form-list-item">
                            <div class="labels"><label class="star">*</label>姓名</div>
                            <div class="flex-box align-items-center gender-box">
                                <div class="left-box">
                                    <van-field 
                                        name="name" 
                                        v-model={form.data.name} 
                                        class="input-box name-box" 
                                        placeholder="请输入真实姓名"
                                        rules={[{ validator: validatorLeng20 }]}
                                         />
                                </div>
                                <div class="right-box">
                                    <van-dropdown-menu>
                                        <van-dropdown-item v-model={form.data.gender} options={genderList} />
                                    </van-dropdown-menu>
                                </div>
                            </div>
                        </div>
                        <div class="form-list-item mt28">
                            <div class="labels"><label class="star">*</label>我是</div>
                            <div>
                                <van-radio-group v-model={form.data.jobGrade} direction="horizontal" onChange={onJobGrade}>
                                    <van-radio name="店长" checked-color="#FE4A49">店长</van-radio>
                                    <van-radio name="区域/商圈经理" checked-color="#FE4A49">区域/商圈经理</van-radio>
                                    <van-radio name="招聘人员" checked-color="#FE4A49">招聘人员</van-radio>
                                    <van-radio name="其他" checked-color="#FE4A49">其他</van-radio>
                                </van-radio-group>
                            </div>
                            { 
                                !bool.value ? '' : (
                                    <div>
                                        <van-field 
                                            name="otherjob"  
                                            v-model={form.data.otherjob} 
                                            class="input-box" 
                                            placeholder="请输入您的职位" 
                                            rules={[{ validator: validatorOtherJob }]}
                                            />
                                    </div>
                                )
                                
                            }
                            
                        </div>
                        <div class="form-list-item mt28">
                            <div class="labels"><label class="star">*</label>手机号码</div>
                            <div>
                                <van-field 
                                    name="phone" 
                                    v-model={form.data.phone} 
                                    class="input-box" 
                                    placeholder="必填" 
                                    rules={[{ validator: validatorPhone}]}
                                     />
                            </div>
                        </div>
                        <div class="code-box-item">
                            <div class="code-input">
                                <van-field name="code" v-model={form.data.code} class="input-box input" placeholder="验证码" />
                            </div>
                            {
                                show.value ? <span class="code-btn" onClick={onOpenVerify}>获取验证码</span> :
                                <button class="button code-btn non-btn" ref={timeNum}>30s获取验证码</button>
                            }
                        </div>
                        <div class="form-list-item company-search-container mt28">
                            <div class="labels"><label class="star">*</label>公司名称</div>
                            <div>
                                <van-field 
                                    name="companyName" 
                                    v-model={form.data.companyName} 
                                    class="input-box" 
                                    placeholder="填写营业执照上的名字" 
                                    rules={[{ validator: validatorCompany }]}
                                     />
                            </div>
                        
                            {
                                companyList.data.length === 0 ? '' : searchCompanyUI()
                            }
                        </div>
                        <div class="form-list-item mt28">
                            <div class="labels"><label class="star">*</label>线下门店牌匾名</div>
                            <div>
                                <van-field 
                                    name="shortName" 
                                    v-model={form.data.shortName} 
                                    class="input-box" 
                                    placeholder="如‘翻腕餐饮’"
                                    rules={[{ validator: validatorShortName }]}
                                     />
                            </div>
                        </div>
                        <div class="form-list-item mt28">
                            <div class="labels">推荐人信息</div>
                            <div class="referrerbox">
                                <div class='Checkbox'>
                                    <Checkbox v-model={checked.value} name="Checkbox">我有推荐人</Checkbox>
                                </div>
                                <div>
                                    <van-field 
                                    name="referrer"  
                                    v-model={form.data.referrer} 
                                    onInput={onSearchPer}
                                    disabled={!checked.value ? true: false}
                                    class="input-box2" 
                                    placeholder="选择您的推荐人" />
                                    <van-popup 
                                        show={showPopup.value}
                                        position="bottom"
                                        round
                                        closeable
                                        onClose={onClosePopup}
                                        style={{ height: '50%', width: '100%' }}
                                        >
                                        <div class="popupTilte">请选择</div>    
                                        <div class="popuplist">
                                            {
                                                perData.data && perData.data.map((item: any) => {
                                                    return (
                                                        <div 
                                                        class="popupItem" 
                                                        key={item.id} 
                                                        onClick={() => onSelectPer(item)}
                                                        >{ item.name }({item.empNo})</div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </van-popup>
                                </div>
                            </div>
                            
                            
                        </div>

                        <div class="footer-item">
                            <div class="labels">提交后，将在1个工作日内审核并为您开通账号</div>
                            <van-button class="sub-btn" type="info" native-type="submit">提交申请</van-button>
                            <div class="footer-font" onClick={onLogin}>已有账号，立即登录</div>
                        </div>
                    </div>
                </van-form>
            </div>
        )
    }
})