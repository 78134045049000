export function throttle(fn, threshhold = 200) {
    let timeout;
    // 计算开始时间
    let start = new Date();
    return function() {
        // 触发时间
        const current = new Date() - 0;
        timeout && clearTimeout(timeout);
        // 如果到了时间间隔点，就执行一次回调
        if (current - start >= threshhold) {
            fn.call(this, ...arguments);
            // 更新开始时间
            start = current;
        } else {
            // 保证方法在脱离事件以后还会执行一次
            timeout = setTimeout(fn.bind(this), threshhold, ...arguments);
        }
    }
}

export function debounce(fn, wait) {
    let timer;
    return function() {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(this, arguments) // 把参数传进去
        }, wait);
    }
}